import {autoInit, Loader, Logger, PubSub} from '@changke/staticnext-lib';
import {i18n} from '../services/i18n';

interface ModuleInitData {
  moduleName: string;
}

class App {
  start(assetPath = {css: '', js: '', modules: ''}) {
    PubSub.subscribe('mod:init', data => {
      Logger.log('app', `Module "${(data as ModuleInitData).moduleName}" initialized.`);
    });

    PubSub.subscribe('mod:dynInit', modRoot => {
      autoInit(modRoot as Element, undefined, Loader, assetPath);
    });

    // Init language data
    if (window.ui && window.ui.pageInfo) {
      const lang = window.ui.pageInfo.lang || 'zh-CN';
      const i18nData = window.ui.i18n;
      if (i18nData && (i18nData.locale === lang)) {
        i18n.init(i18nData.text);
      }
    }

    // Auto initialize modules on page
    autoInit(undefined, undefined, Loader, assetPath);
  }
}

const app = new App();
export default app;
